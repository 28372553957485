
import { Options, prop, Vue } from "vue-class-component";
import Checkbox from "primevue/checkbox";
import { NestedCheckboxItem } from "@/domain/entities/MainApp";

class Props {
  parent = prop<NestedCheckboxItem>({
    required: true,
    type: NestedCheckboxItem,
  });
  childItems = prop<Array<NestedCheckboxItem>>({
    default: [],
    type: Array,
  });
}
@Options({
  emits: ["childUpdated", "parentUpdated"],
  components: {
    Checkbox,
  },
})
export default class NestedCheckbox extends Vue.with(Props) {
  mounted() {
    this.childCheckboxes = this.childItems;
    this.parentCheckboxChecked = this.parent.value;
  }

  parentCheckboxChecked = false;

  childCheckboxes: NestedCheckboxItem[] = [];
  onParentUpdated() {
    this.childCheckboxes = this.childCheckboxes.map(
      (shipment: NestedCheckboxItem) => ({
        ...shipment,
        value: this.parentCheckboxChecked,
      })
    );
    this.$emit("parentUpdated", this.parentCheckboxChecked);
  }

  onChildUpdated(item: NestedCheckboxItem) {
    const selectedChild = this.childCheckboxes.every(
      (shipment: NestedCheckboxItem) => shipment.value
    );
    this.parentCheckboxChecked = selectedChild;
    this.$emit("childUpdated", item);
  }
}
