import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "nested-checkbox" }
const _hoisted_2 = { class: "p-field-checkbox text-left" }
const _hoisted_3 = { class: "ml-8 grid grid-rows-5 grid-flow-col gap-4 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Checkbox, {
        id: _ctx.parent.keyName,
        modelValue: _ctx.parentCheckboxChecked,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.parentCheckboxChecked = $event)),
        onChange: _ctx.onParentUpdated,
        binary: true,
        disabled: _ctx.parent.disabled
      }, null, 8, ["id", "modelValue", "onChange", "disabled"]),
      _createVNode("label", {
        class: 
          `ml-4 text-px xxl:text-${_ctx.parent.textSize}px text-black-lp-100 font-${_ctx.parent.labelWeight}`
        
      }, [
        _createTextVNode(_toDisplayString(_ctx.parent.label) + " ", 1),
        _withDirectives(_createVNode("span", { class: "text-gray-lp-500 font-normal" }, _toDisplayString(_ctx.parent.hint), 513), [
          [_vShow, _ctx.parent.hint]
        ])
      ], 2)
    ]),
    _createVNode("div", _hoisted_3, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.childItems, (item, index) => {
        return (_openBlock(), _createBlock("div", {
          class: "p-field-checkbox text-left",
          key: item.keyName
        }, [
          (_ctx.childCheckboxes[index])
            ? (_openBlock(), _createBlock(_component_Checkbox, {
                key: 0,
                id: item.keyName,
                modelValue: _ctx.childCheckboxes[index].value,
                "onUpdate:modelValue": ($event: any) => (_ctx.childCheckboxes[index].value = $event),
                onChange: ($event: any) => (_ctx.onChildUpdated(item)),
                binary: true,
                disabled: item.disabled
              }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "onChange", "disabled"]))
            : _createCommentVNode("", true),
          _createVNode("label", {
            class: 
            `ml-4 text-px xxl:text-${item.textSize}px text-black-lp-100 font-${item.labelWeight}`
          
          }, [
            _createTextVNode(_toDisplayString(item.label) + " ", 1),
            _withDirectives(_createVNode("span", { class: "text-gray-lp-500 font-normal" }, _toDisplayString(item.hint), 513), [
              [_vShow, item.hint]
            ])
          ], 2)
        ]))
      }), 128))
    ])
  ]))
}